<template>
  <div class="footer">
    <div class="container">
      <div class="row text-lg-left text-center">
        <div class="col-12 col-lg-6">
          <span class="copyright">Copyright &copy; {{ new Date().getFullYear() }}
            <a href="#">AFRO</a> | All right reserved | By
            <a href="https://daragroups.com">DARA GROUPS</a></span>
        </div>
        <div class="col-12 col-lg-6 text-center text-lg-right">
          <ul class="list-inline my-auto social">
            <li class="list-inline-item pr-2">
              <a href="#" class="facebook" title="facebook"><i class="fa fa-facebook"></i></a>
            </li>
            <li class="list-inline-item pr-2">
              <a href="#" class="twitter" title="twitter"><i class="fa fa-twitter"></i></a>
            </li>
            <li class="list-inline-item pr-2">
              <a href="#" class="pinterest" title="pinterest"><i class="fa fa-pinterest-p"></i></a>
            </li>
            <li class="pr-0 list-inline-item pr-0">
              <a href="#" class="linkedin" title="linkedin"><i class="fa fa-linkedin"></i></a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Footer",
  created() {
    // var scripts = [
    //     "/assets/plugins/dist/js/plugins.min.js",
    //     "/assets/js/main.js"
    // ]
    // scripts.forEach(script => {
    //     let tag = document.createElement("script");
    //     tag.setAttribute("src", script);
    //     document.body.append(tag)
    // })
  },
};
</script>