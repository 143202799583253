<template>
  <div>
    <!-- <div class="site-wrap"> -->
    <div class="site-mobile-menu site-navbar-target">
      <div class="site-mobile-menu-header">
        <div class="site-mobile-menu-close mt-3">
          <span class="icon-close2 js-menu-toggle"></span>
        </div>
      </div>
      <div class="site-mobile-menu-body"></div>
    </div>
    <header class="site-navbar py-4 js-sticky-header site-navbar-target" role="banner">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-6">
            <h1 class="mb-0 site-logo"><a href="/" class="h2 mb-0">CEO CLUB<span class="text-primary">.</span>
              </a></h1>
          </div>
          <div class="col-12 col-md-10 d-none d-xl-block">
            <nav class="site-navigation position-relative text-right" role="navigation">
              <ul class="site-menu main-menu js-clone-nav mr-auto d-none d-lg-block">
                <li><a href="/" class="nav-link">Home</a></li>
                <li class="has-children">
                  <a href="#about-section" class="nav-link">About Us</a>
                  <ul class="dropdown">
                    <li><a href="#team-section" class="nav-link">Team</a></li>
                    <li><a href="#pricing-section" class="nav-link d-none">Pricing</a></li>
                    <li><a href="#faq-section" class="nav-link d-none">FAQ</a></li>
                    <!-- <li class="has-children">
                      <a href="#">More Links</a>
                      <ul class="dropdown">
                        <li><a href="#">Menu One</a></li>
                        <li><a href="#">Menu Two</a></li>
                        <li><a href="#">Menu Three</a></li>
                      </ul>
                    </li> -->
                  </ul>
                </li>
                <li><a href="#portfolio-section" class="nav-link d-none">Portfolio</a></li>
                <li><a href="#services-section" class="nav-link">Services</a></li>
                <li><a href="#testimonials-section" class="nav-link d-none">CEO CLUB validation</a></li>
                <li><a href="#blog-section" class="nav-link d-none">Blog</a></li>
                <li><a href="#contact-section" class="nav-link">Contact</a></li>
              </ul>
            </nav>
          </div>
          <div class="col-6 d-inline-block d-xl-none ml-md-0 py-3" style="position: relative; top: 3px;"><a href="#"
              class="site-menu-toggle js-menu-toggle float-right"><span class="icon-menu h3"></span></a></div>
        </div>
      </div>
    </header>
    <div class="site-blocks-cover overlay" style="background-image: url(/assets/images/hero_2.jpg);" data-aos="fade"
      id="home-section">
      <div class="container">
        <div class="row align-items-center justify-content-center">
          <div class="col-md-8 mt-lg-5 text-center">
            <h1 class="text-uppercase" data-aos="fade-up">Welcome</h1>
            <p class="mb-5 desc" data-aos="fade-up" data-aos-delay="100">CEO CLUB is a digital platform
              that provides networking
              events, digital media services, customized profile pages, chatting
              capabilities and other resources to SMEs, business owners, VIPs, ex-CEOs, ex-Managers and Investors using a
              combination of AI and BlockChain.</p>
            <div data-aos="fade-up" data-aos-delay="100">
              <a href="#contact-section" class="btn smoothscroll btn-primary mr-2 mb-2">Request A Demo</a>
            </div>
          </div>
        </div>
      </div>
      <a href="#about-section" class="mouse smoothscroll">
        <span class="mouse-icon">
          <span class="mouse-wheel"></span>
        </span>
      </a>
    </div>
    <div class="site-section cta-big-image" id="about-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" data-aos="fade">
            <h2 class="section-title mb-3">About Us</h2>
          </div>
        </div>
        <div class="row">

          <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
            <div id="demo" class="player">Our AI engine in action</div>
            <!-- <figure class="circle-bg"> -->
            <!-- <img src="/assets/images/great_bis.jpg" alt="Image" class="img-fluid"> -->

            <!-- </figure> -->
          </div>
          <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
            <div class="mb-4">
              <h3 class="h3 mb-4 text-black">For the next great business</h3>
              <p>Ideas are everywhere, many can talk about them and
                present them. It is the execution that is rare. We are
                confident in our idea, but even more confident in our
                ability to execute given our deep experience in the
                industry and multidisciplinary skill set across our team with sixty plus years experiences collectively.
              </p>
            </div>
            <div class="mb-4">
              <ul class="list-unstyled ul-check success">
                <li>Solve information asymmetry in business</li>
                <li>Technology that underpins world-class digital experiences</li>
                <li>One Marketing</li>
                <li>Video ads(Vads)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="site-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-7 text-center">
            <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">Our Features</h2>
            <p class="lead" data-aos="fade-up" data-aos-delay="100">Features that aims to provide a comprehensive suite of
              services that connect businesses together no matter their geographical location.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6 mb-5" data-aos="fade-up" data-aos-delay="">
            <div class="owl-carousel slide-one-item-alt">
              <img src="/assets/images/various_service.jpg" alt="Image" class="img-fluid">
              <img src="/assets/images/ai_power.jpg" alt="Image" class="img-fluid">
              <img src="/assets/images/all-in-one.png" alt="Image" class="img-fluid">
              <!-- <img src="/assets/images/slide_4.jpg" alt="Image" class="img-fluid d-none"> -->
            </div>
            <div class="custom-direction">
              <a href="#" class="custom-prev"><span><span class="icon-keyboard_backspace"></span></span></a><a href="#"
                class="custom-next"><span><span class="icon-keyboard_backspace"></span></span></a>
            </div>
          </div>
          <div class="col-lg-5 ml-auto" data-aos="fade-up" data-aos-delay="100">
            <div class="owl-carousel slide-one-item-alt-text">
              <div>
                <h2 class="section-title mb-3">ACCESS TO VARIOUS SERVICES</h2>
                <p class="lead">Our platform gives access to various type of service with products
                  promotion and marketing campagne, marketing suggestion. We help
                  business to view marketing report and compagne insight to target the
                  right audience.</p>
                <p><a href="#" class="btn btn-primary mr-2 mb-2 d-none">Learn More</a></p>
              </div>
              <div>
                <h2 class="section-title mb-3">AI POWERED PLATFORM</h2>
                <p class="lead">An AI creating a video that describe a company from it’s
                  website content with images, audio. An AI power SME’s
                  connection for business suggestion.</p>
                <p><a href="#" class="btn btn-primary mr-2 mb-2 d-none">Learn More</a></p>
              </div>
              <div>
                <h2 class="section-title mb-3">ALL IN ONE PLATFORM FOR SOCIAL</h2>
                <p class="lead">A platform that bring people together through networking, mentorship,
                  coaching, experience and knowledge sharing.</p>
                <p><a href="#" class="btn btn-primary mr-2 mb-2 d-none">Learn More</a></p>
              </div>
              <!-- <div class="d-none">
                <h2 class="section-title mb-3">4 Our Dedicated Professionals</h2>
                <p class="lead">Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                <p>Est qui eos quasi ratione nostrum excepturi id recusandae fugit omnis ullam pariatur
                  itaque nisi voluptas impedit Quo suscipit omnis iste velit maxime.</p>
                <p><a href="#" class="btn btn-primary mr-2 mb-2">Learn More</a></p>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section border-bottom" id="team-section">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">Our Team</h2>
            <p class="lead" data-aos="fade-up" data-aos-delay="100">With great product comes great responsability.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook d-none"></span></a></li>
                  <li><a href="#"><span class="icon-twitter d-none"></span></a></li>
                  <li><a href="https://www.linkedin.com/in/evarist-fangnikoue-9a328546/"><span
                        class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram d-none"></span></a></li>
                </ul>
                <img src="/assets/images/evarist.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Evarist Fangnikoue</h3>
                <span class="position">Platform and Technology</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
            <div class="team-member">
              <figure>
                <ul class="social d-none">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/stephen.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Stephen Chong</h3>
                <span class="position">Business and Partnership development</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook d-none"></span></a></li>
                  <li><a href="#"><span class="icon-twitter d-none"></span></a></li>
                  <li><a href="https://www.linkedin.com/in/jerome-chalice-6753742/"><span
                        class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram d-none"></span></a></li>
                </ul>
                <img src="/assets/images/jerome.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Jerome Chalice</h3>
                <span class="position">Product & Design</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
            <div class="team-member">
              <figure>
                <ul class="social  d-none">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/ernest.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Dr. Ernest Dodoo</h3>
                <span class="position">Corporate finance, Investor relation</span>
              </div>
            </div>
          </div>
          <!-- <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/person_1.jpg" alt="Image" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Kaiara Spencer</h3>
                <span class="position">Product Manager</span>
              </div>
            </div>
          </div> -->

          <!-- <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="100">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/person_2.jpg" alt="Image" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Dave Simpson</h3>
                <span class="position">Product Manager</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="200">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/person_3.jpg" alt="Image" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Ben Thompson</h3>
                <span class="position">Product Manager</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="300">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook"></span></a></li>
                  <li><a href="#"><span class="icon-twitter"></span></a></li>
                  <li><a href="#"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram"></span></a></li>
                </ul>
                <img src="/assets/images/person_4.jpg" alt="Image" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Chris Stewart</h3>
                <span class="position">Product Manager</span>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </section>
    <section class="site-section border-bottom" id="team-advisor">
      <div class="container">
        <div class="row mb-5 justify-content-center">
          <div class="col-md-8 text-center">
            <h2 class="section-title mb-3" data-aos="fade-up" data-aos-delay="">Our Advisor</h2>
            <p class="lead" data-aos="fade-up" data-aos-delay="100">They have backgrounds in finances and investment</p>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="">
            <div class="team-member">
              <figure>
                <ul class="social d-none">
                  <li><a href="#"><span class="icon-facebook d-none"></span></a></li>
                  <li><a href="#"><span class="icon-twitter d-none"></span></a></li>
                  <li><a href="https://www.linkedin.com/in/simmondsleung/"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram d-none"></span></a></li>
                </ul>
                <img src="/assets/images/jimmy.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Dr. Jimmy LOKE</h3>
                <span class="position">Corporate Governance, Accounting and Auditing</span>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-3 mb-4" data-aos="fade-up" data-aos-delay="">
            <div class="team-member">
              <figure>
                <ul class="social">
                  <li><a href="#"><span class="icon-facebook d-none"></span></a></li>
                  <li><a href="#"><span class="icon-twitter d-none"></span></a></li>
                  <li><a href="https://www.linkedin.com/in/simmondsleung/"><span class="icon-linkedin"></span></a></li>
                  <li><a href="#"><span class="icon-instagram d-none"></span></a></li>
                </ul>
                <img src="/assets/images/simmonds.png" alt="Image" style="width:225px;height: 225px;" class="img-fluid">
              </figure>
              <div class="p-3">
                <h3>Simmonds LEUNG</h3>
                <span class="position">Private wealth management, Business Administration</span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <section class="site-section d-none" id="portfolio-section">
      <div class="container">
        <div class="row mb-3">
          <div class="col-12 text-center" data-aos="fade">
            <h2 class="section-title mb-3">Portfolio</h2>
          </div>
        </div>
        <div class="row justify-content-center mb-5" data-aos="fade-up">
          <div id="filters" class="filters text-center button-group col-md-7">
            <button class="btn btn-primary active" data-filter="*">All</button>
            <button class="btn btn-primary" data-filter=".web">Web</button>
            <button class="btn btn-primary" data-filter=".design">Design</button>
            <button class="btn btn-primary" data-filter=".brand">Brand</button>
          </div>
        </div>
        <div id="posts" class="row no-gutter">
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_1.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_1.jpg">
            </a>
          </div>
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_2.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_2.jpg">
            </a>
          </div>
          <div class="item brand col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_3.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_3.jpg">
            </a>
          </div>
          <div class="item design col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_4.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_4.jpg">
            </a>
          </div>
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_5.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_5.jpg">
            </a>
          </div>
          <div class="item brand col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_6.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_6.jpg">
            </a>
          </div>
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_7.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_7.jpg">
            </a>
          </div>
          <div class="item design col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_8.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_8.jpg">
            </a>
          </div>
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_9.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_9.jpg">
            </a>
          </div>
          <div class="item design col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_10.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_10.jpg">
            </a>
          </div>
          <div class="item brand col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_11.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_11.jpg">
            </a>
          </div>
          <div class="item design col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_12.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_12.jpg">
            </a>
          </div>
          <div class="item web col-sm-6 col-md-4 col-lg-4 col-xl-3 mb-4">
            <a href="/assets/images/img_13.jpg" class="item-wrap fancybox" data-fancybox="gallery2">
              <span class="icon-search2"></span>
              <img class="img-fluid" src="/assets/images/img_13.jpg">
            </a>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section border-bottom bg-light" id="services-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" data-aos="fade">
            <h2 class="section-title mb-3">Our Services</h2>
          </div>
        </div>
        <div class="row align-items-stretch">
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-line-chart"></span></div>
              <div>
                <h3>Analytics</h3>
                <p>Custom tracking solutions for data-driven decisions using AI</p>
                <p><a href="#" class="d-none">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-music_video"></span>
              </div>
              <div>
                <h3>Video Ads(Vads)</h3>
                <p>AI video generated campaigns are highly targeted, trackable, personalized, and provide insights into
                  user behavior.</p>
                <p><a href="#" class="d-none">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-content_copy"></span></div>
              <div>
                <h3>Content Marketing</h3>
                <p>Stay front of mind by crafting valuable content</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-people"></span></div>
              <div>
                <h3>Events & Networking</h3>
                <p class="d-none">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae
                  vitae eligendi at.</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary flaticon-smartphone"></span></div>
              <div>
                <h3>Social Media Campagne</h3>
                <p class="d-none">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae
                  vitae eligendi at.</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-chat"></span></div>
              <div>
                <h3>Chat messaging</h3>
                <p class="d-none">Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis molestiae
                  vitae eligendi at.</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-cubes"></span></div>
              <div>
                <h3>BlockChain contract integration</h3>
                <p>Secure your members' deals with blockchain contract integration! Our platform is
                  designed to provide a safe and reliable way to manage all of your members' deals. Get the most out of
                  your members' deals with our blockchain contract integration!</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="unit-4">
              <div class="unit-4-icon mr-4"><span class="text-primary icon-id-card"></span></div>
              <div>
                <h3>Ecard integration</h3>
                <p>A cards that enable you to follow-up with notifications and meeting point location and more.</p>
                <p class="d-none"><a href="#">Learn More</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section testimonial-wrap d-none" id="testimonials-section" data-aos="fade">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center">
            <h2 class="section-title mb-3">Testimonials</h2>
          </div>
        </div>
      </div>
      <div class="slide-one-item home-slider owl-carousel">
        <div>
          <div class="testimonial">
            <blockquote class="mb-5">
              <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae
                illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="/assets/images/person_3.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
              <p>John Smith</p>
            </figure>
          </div>
        </div>
        <div>
          <div class="testimonial">
            <blockquote class="mb-5">
              <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae
                illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="/assets/images/person_2.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
              <p>Christine Aguilar</p>
            </figure>
          </div>
        </div>
        <div>
          <div class="testimonial">
            <blockquote class="mb-5">
              <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae
                illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="/assets/images/person_4.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
              <p>Robert Spears</p>
            </figure>
          </div>
        </div>
        <div>
          <div class="testimonial">
            <blockquote class="mb-5">
              <p>&ldquo;Lorem ipsum dolor sit amet consectetur adipisicing elit. Consectetur unde
                reprehenderit aperiam quaerat fugiat repudiandae explicabo animi minima fuga beatae
                illum eligendi incidunt consequatur. Amet dolores excepturi earum unde iusto.&rdquo;</p>
            </blockquote>
            <figure class="mb-4 d-flex align-items-center justify-content-center">
              <div><img src="/assets/images/person_4.jpg" alt="Image" class="w-50 img-fluid mb-3"></div>
              <p>Bruce Rogers</p>
            </figure>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section bg-light d-none" id="pricing-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" data-aos="fade-up">
            <h2 class="section-title mb-3">Pricing</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="">
            <div class="pricing">
              <h3 class="text-center text-black">Basic</h3>
              <div class="price text-center mb-4 ">
                <span><span>$47</span> / year</span>
              </div>
              <ul class="list-unstyled ul-check success mb-5">
                <li>Officia quaerat eaque neque</li>
                <li>Possimus aut consequuntur incidunt</li>
                <li class="remove">Lorem ipsum dolor sit amet</li>
                <li class="remove">Consectetur adipisicing elit</li>
                <li class="remove">Dolorum esse odio quas architecto sint</li>
              </ul>
              <p class="text-center">
                <a href="#" class="btn btn-secondary">Buy Now</a>
              </p>
            </div>
          </div>
          <div class="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="pricing">
              <h3 class="text-center text-black">Premium</h3>
              <div class="price text-center mb-4 ">
                <span><span>$200</span> / year</span>
              </div>
              <ul class="list-unstyled ul-check success mb-5">
                <li>Officia quaerat eaque neque</li>
                <li>Possimus aut consequuntur incidunt</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Consectetur adipisicing elit</li>
                <li class="remove">Dolorum esse odio quas architecto sint</li>
              </ul>
              <p class="text-center">
                <a href="#" class="btn btn-primary">Buy Now</a>
              </p>
            </div>
          </div>
          <div class="col-md-6 mb-4 mb-lg-0 col-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="pricing">
              <h3 class="text-center text-black">Professional</h3>
              <div class="price text-center mb-4 ">
                <span><span>$750</span> / year</span>
              </div>
              <ul class="list-unstyled ul-check success mb-5">
                <li>Officia quaerat eaque neque</li>
                <li>Possimus aut consequuntur incidunt</li>
                <li>Lorem ipsum dolor sit amet</li>
                <li>Consectetur adipisicing elit</li>
                <li>Dolorum esse odio quas architecto sint</li>
              </ul>
              <p class="text-center">
                <a href="#" class="btn btn-secondary">Buy Now</a>
              </p>
            </div>
          </div>
        </div>
        <div class="row site-section" id="faq-section">
          <div class="col-12 text-center" data-aos="fade">
            <h2 class="section-title">Frequently Ask Questions</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-6">
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">What available is refund period?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">What available is refund period?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">Where are you from?</h3>
              <p>Voluptatum nobis obcaecati perferendis dolor totam unde dolores quod maxime corporis
                officia et. Distinctio assumenda minima maiores.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">What is your opening time?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">Can I accept both Paypal and Stripe?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
            <div class="mb-5" data-aos="fade-up" data-aos-delay="100">
              <h3 class="text-black h4 mb-4">What available is refund period?</h3>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aliquam assumenda eum blanditiis
                perferendis.</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section d-none" id="about-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-lg-5 ml-auto mb-5 order-1 order-lg-2" data-aos="fade fade-up" data-aos-delay="">
            <img src="/assets/images/about_1.jpg" alt="Image" class="img-fluid rounded">
          </div>
          <div class="col-lg-6 order-2 order-lg-1" data-aos="fade">
            <div class="row">
              <div class="col-md-12 mb-md-5 mb-0 col-lg-6" data-aos="fade-up" data-aos-delay="">
                <div class="unit-4">
                  <div class="unit-4-icon mr-4 mb-3"><span class="text-primary flaticon-head"></span>
                  </div>
                  <div>
                    <h3>Web &amp; Mobile Specialties</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis
                      consect.</p>
                    <p class="mb-0"><a href="#">Learn More</a></p>
                  </div>
                </div>
              </div>
              <div class="col-md-12 mb-md-5 mb-0 col-lg-6" data-aos="fade-up" data-aos-delay="100">
                <div class="unit-4">
                  <div class="unit-4-icon mr-4 mb-3"><span class="text-primary flaticon-smartphone"></span></div>
                  <div>
                    <h3>Intuitive Thinkers</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Perferendis quis.
                    </p>
                    <p class="mb-0"><a href="#">Learn More</a></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section d-none" id="blog-section">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center" data-aos="fade">
            <h2 class="section-title mb-3">Our Blog</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="">
            <div class="h-entry">
              <a href="single.html">
                <img src="/assets/images/img_1.jpg" alt="Image" class="img-fluid">
              </a>
              <h2 class="font-size-regular"><a href="#">Where Do You Learn HTML & CSS in
                  2019?</a></h2>
              <div class="meta mb-4">Ham Brook <span class="mx-2">&bullet;</span> Jan 18, 2019<span
                  class="mx-2">&bullet;</span> <a href="#">News</a></div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores
                sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
              <p><a href="#">Continue Reading...</a></p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="100">
            <div class="h-entry">
              <a href="single.html">
                <img src="/assets/images/img_4.jpg" alt="Image" class="img-fluid">
              </a>
              <h2 class="font-size-regular"><a href="#">Where Do You Learn HTML & CSS in
                  2019?</a></h2>
              <div class="meta mb-4">James Phelps <span class="mx-2">&bullet;</span> Jan 18, 2019<span
                  class="mx-2">&bullet;</span> <a href="#">News</a></div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores
                sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
              <p><a href="#">Continue Reading...</a></p>
            </div>
          </div>
          <div class="col-md-6 col-lg-4 mb-4 mb-lg-4" data-aos="fade-up" data-aos-delay="200">
            <div class="h-entry">
              <a href="single.html">
                <img src="/assets/images/img_3.jpg" alt="Image" class="img-fluid">
              </a>
              <h2 class="font-size-regular"><a href="#">Where Do You Learn HTML & CSS in
                  2019?</a></h2>
              <div class="meta mb-4">James Phelps <span class="mx-2">&bullet;</span> Jan 18, 2019<span
                  class="mx-2">&bullet;</span> <a href="#">News</a></div>
              <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Natus eligendi nobis ea maiores
                sapiente veritatis reprehenderit suscipit quaerat rerum voluptatibus a eius.</p>
              <p><a href="#">Continue Reading...</a></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="site-section bg-light" id="contact-section" data-aos="fade">
      <div class="container">
        <div class="row mb-5">
          <div class="col-12 text-center">
            <h2 class="section-title mb-3">Contact Us</h2>
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-4 text-center">
            <p class="mb-4">
              <span class="icon-room d-block h4 text-primary"></span>
              <span>Unit 708A and B, 7th Floor, Tower 1, Cheung Sha Wan Plaza, 833 Cheung Sha Wan Road, Kowloon, HONG
                KONG</span>
            </p>
          </div>
          <div class="col-md-4 text-center">
            <p class="mb-4">
              <span class="icon-phone d-block h4 text-primary"></span>
              <a href="#">+85291094508</a>
            </p>
          </div>
          <div class="col-md-4 text-center">
            <p class="mb-0">
              <span class="icon-mail_outline d-block h4 text-primary"></span>
              <a href="#"><span class="__cf_email__"
                  data-cfemail="760f190304131b171f1a3612191b171f185815191b">info@ceoclub.daragroups.com</span></a>
            </p>
          </div>
        </div>
        <div class="row d-none">
          <div class="col-md-12 mb-5">
            <form action="#" class="p-5 bg-white">
              <h2 class="h4 text-black mb-5">Contact Form</h2>
              <div class="row form-group">
                <div class="col-md-6 mb-3 mb-md-0">
                  <label class="text-black" for="fname">First Name</label>
                  <input type="text" id="fname" class="form-control">
                </div>
                <div class="col-md-6">
                  <label class="text-black" for="lname">Last Name</label>
                  <input type="text" id="lname" class="form-control">
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="email">Email</label>
                  <input type="email" id="email" class="form-control">
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="subject">Subject</label>
                  <input type="subject" id="subject" class="form-control">
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <label class="text-black" for="message">Message</label>
                  <textarea name="message" id="message" cols="30" rows="7" class="form-control"
                    placeholder="Write your notes or questions here..."></textarea>
                </div>
              </div>
              <div class="row form-group">
                <div class="col-md-12">
                  <input type="submit" value="Send Message" class="btn btn-primary btn-md text-white">
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <!-- </div> -->
    <!-- End Contact -->
  </div>
</template>

<script>
import axios from "axios";
import validator from "validator";

export default {
  name: "Home",
  data() {
    return {
      errors: {},
      messageRes: null,
      userContact: {
        fullName: "",
        email: "",
        message: "",
      },
    };
  },
  methods: {
    sendMessage() {
      this.errors = {};
      if (validator.isEmpty(this.userContact.fullName))
        this.errors.fullName = "Full name is required!";
      if (validator.isEmpty(this.userContact.email))
        this.errors.email = "Email is required!";
      if (!validator.isEmail(this.userContact.email))
        this.errors.email = "Email must be valid!";
      if (validator.isEmpty(this.userContact.message))
        this.errors.message = "Message is required!";
      if (!Object.keys(this.errors).length) {
        let self = this;
        axios
          .post(`${process.env.VUE_APP_API}/api/send/mail`, this.userContact)
          .then((res) => {
            self.messageRes = res.data.message;
            self.resetForm();
          })
          .catch((err) => { });
      }
    },
    resetForm() {
      this.userContact = {};
    },
  },
};
</script>
